import React, { Component } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
// import differenceInDays from 'date-fns/difference_in_days'
// import format from 'date-fns/format'
import moment from 'moment'

import API from '~/modules/API'
import Loading from '~/widgets/Loading'
import Alert from '~/widgets/Alert'
import FreemiumBar from '~/widgets/FreemiumBar/FreemiumBar'

import SelectionGallery from './Gallery'
import SelectionHeader from './Header'
import SelectionCheckout from './Checkout'
import SelectionSummary from './Summary'
import SelectionStatusBar from './StatusBar'
import SelectionPicturePreview from './PicturePreview'
import LoginModal from './LoginModal'
import SelectionContext from './context'

import './style.scss'

// TODO: TÍTULO E OG METATAGS
class Selection extends Component {
  static propTypes = {
    router: PropTypes.object,
    location: PropTypes.any
  }
  connRetries = 0

  constructor(props, context) {
    super(props, context)

    this.state = {
      hashid: this.props.router.params.hashid,
      displayName: '',
      cover: '',
      coverKey: '',
      loading: true,
      error: false,
      showPreview: false,
      showCommentBox: false,
      showSelectedOnly: false,
      highlightedPictureId: 0,
      visiblePictures: [],

      collection: false,
      selection: false,
      client: false,
      selected_pictures: {},
      picturesMap: {},
      picturesCount: 0,
      isGenericLink: false,
      isPasswordProtected: false,
      isLoggedIn: false,
      linkHash: false,

      previewToggle: this.previewToggle.bind(this),
      selectedOnlyToggle: this.selectedOnlyToggle.bind(this),
      toggleCommentBox: this.toggleCommentBox.bind(this),
      getPictureData: this.getPictureData.bind(this),
      getSelectedCount: this.getSelectedCount.bind(this),
      getSelectedPictures: this.getSelectedPictures.bind(this),
      setHighlightedPicture: this.setHighlightedPicture.bind(this),
      setPictureState: this.setPictureState.bind(this),
      finishSelection: this.finishSelection.bind(this),
      updateVisiblePicturesArray: this.updateVisiblePicturesArray.bind(this),

      goToCheckout: () => {
        window.location = `/s/${this.state.hashid}/checkout`
        // this.props.router.push(`/s/${this.state.hashid}/checkout`)
      },
      goToGallery: () => {
        this.updateVisiblePicturesArray.bind(this)(),
          this.props.router.push(`/s/${this.state.hashid}`)
      }
    }

    this.onContextMenu = this.onContextMenu.bind(this)
    this.getData = this.getData.bind(this)
    this.postData = this.postData.bind(this)
    this.setupPreviewByURL = this.setupPreviewByURL.bind(this)
    this.openLoginModal = this.openLoginModal.bind(this)
  }

  async componentDidMount() {
    window.addEventListener('contextmenu', this.onContextMenu)
    this.props.router.listen(this.onRouteChange.bind(this))
    await this.getData()
  }

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.onContextMenu)
  }

  onRouteChange(lastLocation) {
    const currentLocation = this.props.router.location

    if (currentLocation.pathname.includes('/login') && !swal.getState().isOpen) {
      return this.openLoginModal()
    }

    // atualiza o preview quando os botões 'voltar' ou 'próxima' do navegador
    // são ativados
    if (currentLocation.hash && currentLocation.hash.length && lastLocation.hash === currentLocation.hash) {
      return
    }

    this.setupPreviewByURL()
  }

  onContextMenu(e) {
    if (this.state && this.state.selection && this.state.selection.allow_copy) {
      return
    }
    e.preventDefault()
  }

  getPictureData(pictureId) {
    return {
      ...(this.state.picturesMap[pictureId]),
      ...(this.state.selected_pictures[pictureId] || {}),
    }
  }

  getSelectedCount() {
    return Object.keys(this.state.selected_pictures).length
  }

  getSelectedPictures() {
    return Object.keys(this.state.selected_pictures).sort((a, b) => {
      return (+this.state.picturesMap[a].index) - (+this.state.picturesMap[b].index)
    })
  }

  previewToggle(pictureId) {
    const { pathname } = this.props.router.location
    if (!pictureId) {
      this.setState({
        highlightedPictureId: 0,
        showPreview: false
      })
      this.props.router.push(pathname)
      return
    }
    if (this.state.showPreview) {
      this.props.router.replace(`${pathname}#/preview/${pictureId}`)
    } else {
      this.props.router.push(`${pathname}#/preview/${pictureId}`)
    }
    this.setState({
      highlightedPictureId: pictureId,
      showPreview: true
    })
  }

  toggleCommentBox(pictureId) {
    if (this.state.showCommentBox) {
      const { hash } = this.props.router.location

      if (!hash.length) {
        return this.setState({
          highlightedPictureId: 0,
          showCommentBox: false,
          showPreview: false
        })
      }

      return this.setState({ showCommentBox: false })
    }

    this.setState({
      highlightedPictureId: pictureId,
      showCommentBox: true,
      showPreview: true
    })
  }

  async selectedOnlyToggle(selectedOnly = false) {
    this.setState({
      showSelectedOnly: (!this.state.showSelectedOnly) || selectedOnly,
      highlightedPictureId: 0,
      showCommentBox: false,
      showPreview: false
    }, () => {
      this.updateVisiblePicturesArray()
    })
  }

  openLoginModal() {
    return LoginModal.show(this.state.hashid, this.state.isGenericLink)
  }

  setHighlightedPicture(pictureId) {
    this.setState({ highlightedPictureId: pictureId })
  }

  async setPictureState(pictureId, selected, comment) {
    const { selected_pictures, selection, isGenericLink } = this.state

    if (isGenericLink) {
      return this.openLoginModal()
    }

    if (!selected) {
      delete selected_pictures[pictureId]
    } else if (!selected_pictures[pictureId] || !selected_pictures[pictureId].selected) {
      if (selection.contracted_pictures && this.getSelectedCount() === selection.contracted_pictures) {
        if (!selection.allow_extra) {
          return await swal({
            text: `Você atingiu o limite de ${selection.contracted_pictures} fotos! Para selecionar esta foto você deve desmarcar alguma foto já selecionada.`,
            icon: false,
            dangerMode: true,
            buttons: {
              confirm: 'Ok'
            }
          })
        }

        if (selection.allow_extra && selection.extra_cost) {
          await swal({
            text: `Você atingiu o limite de ${selection.contracted_pictures} fotos! O preço de cada foto extra selecionada é R$ ${selection.extra_cost.toFixed(2).replace('.', ',')}.`,
            icon: false,
            dangerMode: true,
            buttons: {
              confirm: 'Ok'
            }
          })
        }
      }

      selected_pictures[pictureId] = { selected, comment }
    } else {
      selected_pictures[pictureId] = { selected, comment }
    }

    this.setState({ selected_pictures }, () => this.updateVisiblePicturesArray())
    this.postData()
  }

  async finishSelection() {
    const willFinish = await swal({
      className: 'swal-justify',
      text: `Ao concluir a seleção não será mais possível selecionar novas fotos ou adicionar comentários. Após a conclusão você receberá um email com a confirmação da seleção.

             Deseja continuar?`,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Concluir seleção',
          value: true,
          visible: true,
          className: '',
          closeModal: false
        }
      }
    })
    if (!willFinish) return false
    await API.checkoutSelection(this.state.hashid, this.state.selected_pictures)
    await swal({
      text: 'Seleção concluída!',
      icon: 'success',
      buttons: {
        confirm: 'Ok'
      }
    })

    window.location.reload()
  }

  get isCheckoutPage() {
    const { subpage } = this.props.router.params
    return subpage && subpage === 'checkout'
  }

  get isLoginPage() {
    const { subpage } = this.props.router.params
    return subpage && subpage === 'login'
  }

  get isSummaryPage() {
    const { subpage } = this.props.router.params
    return subpage && subpage === 'summary'
  }

  get isGalleryPage() {
    return !this.isCheckoutPage && !this.isSummaryPage
  }

  async updateVisiblePicturesArray() {
    if (this.state.showSelectedOnly || this.isCheckoutPage) {
      this.setState({ visiblePictures: this.getSelectedPictures() })
      return
    }

    return this.setState({
      visiblePictures: Object.keys(this.state.picturesMap).sort((a, b) => {
        return (+this.state.picturesMap[a].index) - (+this.state.picturesMap[b].index)
      })
    })
  }

  async setupPreviewByURL() {
    const hashParams = (this.props.router.location.hash || '').split('#').pop().split('/')
    const isPreviewPage = hashParams && hashParams.length && hashParams[1] === 'preview'

    this.setState({
      showPreview: isPreviewPage,
      highlightedPictureId: +hashParams[2] || 0,
    })
  }

  async getData() {
    const data = await API.getSelection(this.state.hashid)
    let days_left = 0

    if (data.limit_date) {
      days_left = moment(data.limit_date).endOf("day").diff(moment().endOf("day"), 'days')
    }

    if (data && data.error && data.error.message === 'Failed to fetch') {
      if (this.connRetries <= 3) {
        this.setState({ ready: true, error: 'ConnectionFailedError' })
        setTimeout(this.getData.bind(this), 6e3)
        this.connRetries++
        return
      }
      this.setState({ ready: true, error: 'ConnectionFailedError2' })
      return
    }

    if (!data || !data.customer || !data.hash || data.error) {
      this.setState({ ready: true, error: 'InvalidDataError' })
      return
    }

    if (data.hash && data.hash !== this.state.hashid) {
      window.location = `/s/${data.hash}`
      return
    }

    const displayName = (data.customer || {}).displayName

    document.title = `${displayName} - ${data.title}`

    this.setState({
      loading: false,
      error: false,
      displayName,
      cover: data.cover,
      coverKey: data.cover_key,
      customization: {
        ...data.default_customization,
        ...data.customization
      },
      collection: {
        title: data.title,
        description: data.description
      },
      customer: data.customer,
      selected_pictures: data.selected_pictures || ({}),
      picturesMap: data.picturesMap || ({}),
      picturesCount: Object.keys(data.picturesMap || {}).length,
      isGenericLink: data.isGenericLink,
      isPasswordProtected: data.isPasswordProtected,
      isLoggedIn: data.isLoggedIn,
      linkHash: data.linkHash,
      client: data.client,
      selection: {
        status: data.status,
        contracted_pictures: data.contracted_pictures,
        extra_cost: data.extra_picture_price,
        allow_extra: data.allow_extra_pictures,
        allow_copy: data.allow_copy,
        limit_date: data.limit_date && moment(data.limit_date).format('DD/MM/YYYY'),
        days_left,
        is_expired: data.status === 0 && (days_left < 0),
        enabled: (data.status === 0 && (days_left >= 0)) || data.isGenericLink
      },
    }, () => {
      this.updateVisiblePicturesArray()
      this.setupPreviewByURL()
    })

    this.updateAccessLog(this.state.hashid)
  }

  updateAccessLog(hash) {
    if (!hash) {
        return
    }
    const key = `sel-${hash}-last_access`
    let last_access
    try {
        last_access = JSON.parse(localStorage.getItem(key))
    }
    catch (ex) {
        last_access = null
    }
    if (last_access && Date.now() - last_access < 144e5) {
        return
    }
    localStorage.setItem(key, Date.now())
    API.pushAccessLog({ hash, service: 'select' }, true).catch(e => console.warn(e))
}

  async postData() {
    await API.postSelection(this.state.hashid, this.state.selected_pictures)
  }

  get errorMessage() {
    switch (this.state.error) {
      case 'InvalidDataError': return 'Coleção não encontrada.'
      case 'ConnectionFailedError': return (<span><i className='fa fa-spinner fa-pulse'></i> Ops! Você pode ter ficado sem internet ou os nossos servidores estão sendo atualizados, aguarde um instante enquanto tentamos resolver.</span>)
      case 'ConnectionFailedError2': return 'Poxa, não conseguimos estabelecer uma conexão com os servidores, verifique a sua conexão de internet ou tente novamente mais tarde.'
      default: return 'Algo deu errado.'
    }
  }

  render() {
    const selectionEnabled = this.state.selection && ((!this.state.selection.is_expired && this.state.selection.status === 0) || this.state.selection.enabled)

    if (this.state.error) {
      return (
        <section className='sel sel--loading'>
          <div className='error-message'>
            {this.errorMessage}
          </div>
        </section>
      )
    }

    if (this.state.loading) {
      return (
        <section className='sel sel--loading'>
          <Loading />
        </section>
      )
    }

    if (this.state.isPasswordProtected && !this.state.isLoggedIn) {
      return (
        <section className='sel sel--login'>
          <div className='login-box'>
            <LoginModal
              hash={this.state.hashid}
              isGenericLink={this.state.isGenericLink}
              client={this.state.client}
            />
          </div>
        </section>
      )
    }

    if (this.isCheckoutPage && !selectionEnabled) {
      this.props.router.push(`/s/${this.state.hashid}`)
      return false
    }

    return (
      <SelectionContext.Provider value={this.state}>
        <div className={(
          'sel ' +
          `${this.isCheckoutPage ? 'sel--checkout' : ''} ` +
          `${this.isGalleryPage ? 'sel--gallery' : ''} ` +
          `${this.isSummaryPage ? 'sel--summary' : ''} ` +
          `${selectionEnabled ? '' : 'sel--closed'} ${this.state.showPreview ? 'sel--preview' : ''}`
        )}>
          {this.state.showPreview && (
            <SelectionPicturePreview
              visiblePictures={this.state.visiblePictures}
              picturesCount={this.state.picturesCount}
            />
          )}
          {this.state.customer && this.state.customer.isFreemium &&
            <FreemiumBar />
          }
          {!this.isSummaryPage && <SelectionHeader
            picturesCount={this.state.picturesCount}
            isCheckoutPage={this.isCheckoutPage}
          />}
          <SelectionStatusBar
            openLoginModal={this.openLoginModal}
            isCheckoutPage={this.isCheckoutPage}
            isSummaryPage={this.isSummaryPage}
            picturesCount={this.state.picturesCount}
          />
          <div id='page-content'>
            {!this.isSummaryPage && (
              this.state.selection.status === 1 ?
                (<Alert.Success>
                  <strong>SELEÇÃO CONCLUÍDA COM SUCESSO!</strong>
                  <br />
                  <p>
                    Já fomos notificados que você finalizou as escolhas das fotos.<br />
                    Você ainda pode ver todas as suas fotos abaixo, porém não será mais possível selecionar ou desmarcar fotos. Se por algum motivo for necessário fazer qualquer alteração, entre em contato para que a seleção seja liberada.
                  </p>
                </Alert.Success>)
                :
                (
                  this.state.selection.days_left < 0 &&
                  <Alert.Danger>
                    <div style={{ color: 'rgba(75,25,25, .9)' }}>
                      O prazo para a seleção da fotos expirou em {this.state.selection.limit_date}. Entre em contato com seu fotógrafo.
                      </div>
                  </Alert.Danger>
                )
            )
            }
            {this.isCheckoutPage && (<SelectionCheckout picturesCount={this.state.picturesCount} />)}
            {this.isSummaryPage && <SelectionSummary />}
            {this.isGalleryPage && !!this.state.visiblePictures.length && (
              <SelectionGallery
                key={`ps-gallery-${this.state.visiblePictures.length}`}
                selectionEnabled={selectionEnabled}
                visiblePictures={this.state.visiblePictures}
                setPictureState={this.state.setPictureState}
                toggleCommentBox={this.state.toggleCommentBox}
                previewToggle={this.state.previewToggle}
                allowCopy={this.state.selection.allow_copy}
              />
            )}
          </div>

          <footer>
            <p>
              {this.state.displayName}, Copyright &copy; {(new Date).getFullYear()} | Todos os direitos reservados
            </p>
            <p>
              <span>by </span>
              <a href="https://www.picsize.com.br/" target="_blank">
                PICSIZE
              </a>
            </p>

          </footer>
        </div>
      </SelectionContext.Provider>
    )
  }
}

// function fixedTime (isodate) {
//   const time = 'T05:00:00.000Z'
//   return isodate.split('T')[0] + time
// }

export default Selection
