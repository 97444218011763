import React, { Component } from 'react'
import { Button } from 'antd'

import Utils from '~/modules/Utils'
import Icons from '~/widgets/Icon'
import GalleryContext from '../GalleryContext'
import FullDialogs from './FullDialogs'

import './InstallApp.scss'

export default {
    open(props = {}) {
        FullDialogs.open(<InstallApp {...props} />)
    }
}

class InstallApp extends Component {
    static contextType = GalleryContext

    constructor(props, context) {
        super(props, context)

        this.state = {
            customer: false,
            cover: false,
            title: false
        }
    }

    componentDidMount() {
        const { data } = this.props

        if (!data || !data.customer) {
            console.warn('invalid data received')
            return
        }

        this.setState({
            customer: data.customer,
            cover: data.cover,
            title: data.title
        })
    }

    componentWillUnmount() {
    }

    copyLink() {
        const copyText = document.getElementById('linkInp')
        copyText.select()
        document.execCommand('copy')
        alert('Link copiado!')
    }

    get isReady() { return this.state && this.state.customer }

    render() {
        if (!this.isReady) {
            return null
        }

        if (Utils.isMobileOrTablet()) {
            if (!!window.chrome || !('serviceWorker' in navigator)) {
                return this.renderMobile()
            }
            return this.renderUnsuported()
        }

        return this.renderDesktop()
    }

    renderDesktop() {
        return (
            <div className='sf-gallery-install-app'>
                <div className='install-card'>
                    <h4 className='customer-name'>{this.state.customer.displayName}</h4>

                    <div>
                        {this.state.cover && <img className='app-icon' src={this.state.cover}></img>}
                        <h2 className='app-name'>{this.state.title}</h2>
                    </div>

                    <p>
                        Você pode instalar o aplicativo da galeria em um dispositivo móvel.
                        Para instalar o aplicato, abra este link no navegador do seu smartphone ou tablet.
                    </p>

                    <Button onClick={this.props.fancyClose}>Ver Galeria</Button>
                </div>
            </div>
        )
    }

    renderUnsuported() {
        return (
            <div className='sf-gallery-install-app'>
                <div className='install-card'>
                    <h4 className='customer-name'>{this.state.customer.displayName}</h4>

                    <div>
                        {this.state.cover && <img className='app-icon' src={this.state.cover}></img>}
                        <h2 className='app-name'>{this.state.title}</h2>
                    </div>

                    <div className='install-instructions'>
                        Você pode instalar o aplicativo da galeria em um dispositivo móvel.
                        Para instalar o aplicato neste dispositivo, abra esse link no aplicativo do navegador Chrome.
                        <div>
                            <input type='text' readOnly defaultValue={window.location.href} id="linkInp" />
                            <Button type='primary' onClick={this.copyLink}>Copiar link</Button>
                        </div>
                    </div>

                    <Button onClick={this.props.fancyClose}>Ver Galeria</Button>
                </div>
            </div>
        )
    }

    renderMobile() {
        return (
            <div className='sf-gallery-install-app'>
                <div className='install-card'>
                    <h4 className='customer-name'>{this.state.customer.displayName}</h4>

                    <div>
                        {this.state.cover && <img className='app-icon' src={this.state.cover}></img>}
                        <h2 className='app-name'>{this.state.title}</h2>
                    </div>

                    <div className='install-instructions'>
                        Toque em {this.mobileMenuIcon()} e depois em <strong>"Adicionar à tela inicial"</strong>
                        &nbsp; para instalar este aplicativo.
                    </div>

                    {/* <div>ou</div>
                    <br />
                    <Button onClick={this.props.fancyClose}>Clique Aqui</Button>
                    <br />
                    <br />
                    <div>para ver a galeria sem instalar o aplicativo.</div> */}
                    <a className='no-install-link' onClick={this.props.fancyClose}>veja sem instalar</a>

                </div>
            </div>
        )
    }

    mobileMenuIcon() {
        if (navigator.userAgent.toLowerCase().indexOf("iphone") !== -1) {
            return <Icons.IphoneMenu className='menu-icon' />
        }
        return <i className='menu-icon fa fa-ellipsis-v' />
    }

}
