import 'intersection-observer'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GalleryItem from './Item'
import SelectionContext from '../context'
import * as MosaicGrid from './grids/mosaic-2'

import './style.scss'

class SelectionGallery extends Component {
  static contextType = SelectionContext
  appEl = null
  picturesMap = new Map()
  resizeTimeout = null

  constructor(props, context) {
    super(props, context)

    this.state = {
      mountedPictures: []
    }

    this.handleObserverUpdate = this.handleObserverUpdate.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.incrementMountedPictures = this.incrementMountedPictures.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.getPicture = this.getPicture.bind(this)
    this.observer = new IntersectionObserver(this.handleObserverUpdate, {
      rootMargin: `${window.screen.availHeight * 1.5}px 0px`
    })
  }

  componentDidMount() {
    this.context.updateVisiblePicturesArray()
    window.addEventListener('scroll', this.handleScroll, false)
    window.addEventListener('resize', this.handleResize)
    this.calc()
    this.incrementMountedPictures()
    this.appEl = document.querySelector('#app')
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false)
    window.removeEventListener('resize', this.handleResize)
    this.observer.disconnect()
  }

  handleScroll() {
    if (this.appEl.scrollHeight - (window.scrollY + document.body.offsetHeight) < 300) {
      this.incrementMountedPictures()
    }
  }

  handleResize() {
    if (this.resizeTimeout) {
      window.clearTimeout(this.resizeTimeout)
    }
    this.resizeTimeout = setTimeout(() => {
      this.calc()
      this.setState({ mountedPictures: this.state.mountedPictures.slice(0) })
      this.resizeTimeout = null
    }, 2e2)
  }

  calc() {
    const pictures = this.props.visiblePictures.map(id => this.context.getPictureData(id))
    const container = document.getElementById('sf-gal-gallery-box')
    MosaicGrid.calc(pictures, container.clientWidth)
    for (const p of pictures) {
      this.picturesMap.set(p.id, p)
    }
  }

  getPicture(id) {
    return this.picturesMap.get(id)
  }

  incrementMountedPictures() {
    this.setState({
      mountedPictures: this.props.visiblePictures.slice(0, this.state.mountedPictures.length + 25)
    })
  }

  handleObserverUpdate(e) {
    for (const entry of e) {
      const { top, left, bottom, right } = entry.intersectionRect
      if ([top, bottom, left, right].some(Boolean)) {
        entry.target.parentElement.classList.add('sel__gallery__item--visible')
        entry.target.attributes['data-is-visible'].value = '1'
        entry.target.src = entry.target.attributes['data-thumb-src'].value
      } else {
        entry.target.parentElement.classList.remove('sel__gallery__item--visible')
        entry.target.attributes['data-is-visible'].value = '0'
        entry.target.src = entry.target.attributes['data-mini-src'].value
      }
    }
  }

  render() {
    return (
      <div className="gal__gallery gallery-mosaic-grid" id='sf-gal-gallery-box'>
        {this.props.visiblePictures
          .filter(picId => this.state.mountedPictures.includes(picId))
          .map(picId => {
            const pic = this.getPicture(+picId)
            if (!pic) return null
            return (
              <GalleryItem
                key={`gallery-pic-${pic.id}-index`}
                id={`g-pic-${pic.id}`}
                picture={pic}
                width={pic.width}
                height={pic.height}
                x={pic.x}
                y={pic.y}
                maxWidth={pic.maxWidth}
                minWidth={pic.minWidth}
                selectionEnabled={this.props.selectionEnabled}
                isSelected={pic.selected}
                isCommented={!!pic.comment && !!pic.comment.length}
                setPictureState={selected => this.props.setPictureState(pic.id, selected)}
                toggleCommentBox={() => this.props.toggleCommentBox(pic.id)}
                openPicturePreview={() => this.props.previewToggle(pic.id)}
                observer={this.observer}
                download={this.context.downloadPicture}
                allowShare={this.props.allowShare}
                allowCopy={this.props.allowCopy}
                share={this.props.share}
              />
            )
          })}
      </div>
    )
  }
}

SelectionGallery.propTypes = {
  collection: PropTypes.any,
  visiblePictures: PropTypes.array,
  selectionEnabled: PropTypes.any,
  allowCopy: PropTypes.bool,
  allowShare: PropTypes.bool,
  setPictureState: PropTypes.func,
  toggleCommentBox: PropTypes.func,
  previewToggle: PropTypes.func,
  share: PropTypes.func
}

export default SelectionGallery
