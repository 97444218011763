import React, {Component} from 'react'
import PropTypes from 'prop-types'

import InfoTable from 'app/widgets/InfoTable'
import Button from 'app/widgets/Button'
import Alert from 'app/widgets/Alert'

import SelectionContext from '../../context'

import './style.scss'

class SelectionCheckoutOverview extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)
  }

  render () {
    let {contracted_pictures, allow_extra, extra_cost} = this.context.selection
    const selectedCount = this.context.getSelectedCount()
    const extra_count = allow_extra && (selectedCount - contracted_pictures)
    const total_price = extra_count && ((extra_cost * extra_count).toFixed(2)).replace('.', ',')
    extra_cost = extra_cost && extra_cost.toFixed(2).replace('.', ',')

    return (
      <section className="sel__checkout__overview">
        <h3>Resumo da seleção</h3>
        <InfoTable>
          <InfoTable.Item name="Total de fotos" value={this.props.picturesCount} />
          <InfoTable.Item name="Fotos contratadas" value={contracted_pictures} />
          <InfoTable.Item name="Fotos selecionadas" value={selectedCount} />
          {!!extra_count && (extra_count > 0) && [
            <InfoTable.Item name="Fotos adicionais " value={extra_count} key="checkout-it1" />,
            <InfoTable.Item name="Valor por foto adicional" value={`R$ ${extra_cost}`} key="checkout-it2" />,
            <InfoTable.Item name="Valor a ser pago" value={`R$ ${total_price}`} key="checkout-it3" />,
            <InfoTable.Item isInfo value="(A forma de pagamento deverá ser combinada diretamente com o fotógrafo.) " key="checkout-it4" />
          ]}
        </InfoTable>
        <Alert.Warning>
        Antes de concluir, confira atentamente as fotos selecionadas ao lado.
        </Alert.Warning>
        <Button isSpecial onClick={() => {
            // this.context.goToGallery
            window.history.back()
          }}>
        Continuar selecionando
        </Button>
        <Button.Success onClick={this.context.finishSelection}>
        Concluir seleção
        </Button.Success>
      </section>
    )
  }
}

SelectionCheckoutOverview.propTypes = {
  collection: PropTypes.any
}

export default SelectionCheckoutOverview
