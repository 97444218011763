import React, { Component } from 'react'
import { Link, scroller } from "react-scroll"

import API from '~/modules/API'
import Icon from '~/widgets/Icon'

import SelectionContext from '../context'

import './style.scss'

class SelectionHeader extends Component {
  static contextType = SelectionContext
  shouldUnload = false
  headerWrap;

  constructor(props, context) {
    super(props, context)
    this.state = {
      coverUrl: '',
      hasCover: false,
      coverReady: false,
      blur: ''
    }
    this.unloadCover = this.unloadCover.bind(this)
    this.loadCover = this.loadCover.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }

  componentDidMount() {
    this.headerWrap = document.getElementById('header-wrap')
    window.addEventListener('scroll', this.onScroll, true)
    this.loadCover()
    if (this.props.isCheckoutPage) {
      setTimeout(() => scroller.scrollTo('page-content', { offset: -45 }), 1e2)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  onScroll() {
    if (window.scrollY > this.headerWrap.clientHeight) {
      if (!this.shouldUnload) {
        this.unloadCover();
        this.shouldUnload = true
      }
    }
    else {
      if (this.shouldUnload) {
        this.loadCover();
        this.shouldUnload = false
      }
    }
  }

  unloadCover() {
    const { coverKey, cover } = this.context

    const coverPath = typeof coverKey === 'string' ? coverKey.split('customer/')[1] : cover.split('customer/')[1]
    this.setCover(`https://img.picsize.com.br/thumb/max/32/${coverPath}`)
  }

  loadCover() {
    const { coverKey, cover } = this.context

    let width
    const vMax = Math.max(screen.width, screen.height)
    const verticalScreen = screen.width < screen.height

    if (vMax <= 800) {
      width = 768
    }
    else if (vMax <= 1200) {
      width = 1080
    }
    else if (vMax <= 1500) {
      width = 1440
    }
    else {
      width = 1920
    }

    if (!coverKey) {
      this.setCover()
      return
    }

    const coverPath = typeof coverKey === 'string' ? coverKey.split('customer/')[1] : cover.split('customer/')[1]
    if (verticalScreen) {
      this.setCover(`https://img.picsize.com.br/thumb/${width}/${width}/${coverPath}`)
    }
    else {
      this.setCover(`https://img.picsize.com.br/thumb/max/${width}/${coverPath}`)
    }
  }

  setCover(url) {
    let img = document.createElement('img')
    img.src = url
    this.setState({ coverUrl: url })

    if (!url) {
      this.setState({ hasCover: false, coverReady: true })
    }

    function _loaded() {
      this.setBlur(img.width, img.height)

      this.setState({ hasCover: true })
      setTimeout(() => this.setState({ coverReady: true }), 1e2)
      window.addEventListener('resize', () => this.setBlur(img.width, img.height))
    }

    if (img.complete) {
      _loaded.bind(this)()
    } else {
      img.addEventListener('load', _loaded.bind(this))
      img.addEventListener('error', () => {
        this.setState({ hasCover: false, coverReady: true })
      })
    }
  }

  setBlur(w, h) {
    const d = Math.max(screen.width - w, screen.height - h)
    if (d <= 0) {
      this.setState({
        blur: ''
      })
    } else if (d < 100) {
      this.setState({
        blur: 'blur-1'
      })
    } else if (d < 200) {
      this.setState({
        blur: 'blur-2'
      })
    } else if (d < 400) {
      this.setState({
        blur: 'blur-3'
      })
    } else if (d < 1200) {
      this.setState({
        blur: 'blur-4'
      })
    } else {
      this.setState({
        blur: 'blur-5'
      })
    }
  }

  render() {
    const { customization, collection, isLoggedIn, client, linkHash } = this.context

    return (
      <div id='header-wrap' className={`header-wrap ${this.state.coverReady ? '' : 'loading-cover'}`}>
        {this.state.hasCover &&
          <div className={`header-background ${this.state.blur}`} style={{ background: `url(${this.state.coverUrl})` }}></div>
        }

        <div className="sel__header" id="sel-header">

          <div className='client-data'>
            {client && <span>Olá, {client.name}</span>}
            {(client && isLoggedIn === true) && (
              <span>
                (<a onClick={async () => {
                  await API.postLogout()
                  if (linkHash) {
                    window.location = `/s/${linkHash}`
                    return
                  }
                  window.location.reload()
                }}>Sair</a>)
              </span>
            )}
          </div>

          <div className='collection-title'>
            <h2>{collection.title}</h2>
            {collection.event_date ? <p>{collection.event_date}</p> : null}
          </div>

          <div className='call-to-action-wrap'>
            <Link
              className='call-to-action-btn'
              to="page-content"
              // offset={-45}
              duration={500}
              delay={0}
              smooth={true}
            >Ver Fotos</Link>
          </div>

          {(
            !!customization && !!customization.logo &&
            <div className='custom-logo'>
              <img src={customization.logo} />
            </div>
          )}

          <div className='arrow-indicator'>
            <div className='vert-anim-t'><Icon.AngleDown /></div>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectionHeader
