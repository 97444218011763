import GalleryPage from './pages/Gallery/GalleryPage'
import GalleryPicturePage from './pages/Gallery/picture'
import SelectionPage from './pages/Selection'
import RecoverPage from './pages/Recover'

const routes = {
  childRoutes: [
    {
      path: '/',
      // component: Main,
      // indexRoute: {onEnter: (nextState, replace) => replace('/collection/list')},
      // onEnter: (nextState, replace) => {
      //   // if (!Auth.isAuthenticated) {
      //   //   return replace('/login')
      //   // }
      // },
      childRoutes: [
        {path: 'g/:hashid', component: GalleryPage},
        {path: 'p/:hashid', component: GalleryPicturePage},
        {path: 's/:hashid', component: SelectionPage},
        {path: 's/:hashid/:subpage', component: SelectionPage},
        {path: 's/:hashid/:subpage/:picid', component: SelectionPage},
        {path: 'r/:hashid/:reset_token', component: RecoverPage},
        // {path: ':hashid/:picid', component: GalleryPicturePage},
        {path: ':hashid', component: GalleryPage},
      ]
    }
  ]
}

export default routes
