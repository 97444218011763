import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loading from '~/widgets/Loading'
import Utils from '~/modules/Utils'
import API from '~/modules/API'
import GalleryContext from './GalleryContext'
import InstallApp from './FullDialogs/InstallApp'
import GalleryPicturesPage from './GalleryPicturesPage'

import './GalleryPage.scss'

export default class GalleryPage extends Component {
  connRetries = 0
  isMobile = false
  isPWA = false

  constructor(props, context) {
    super(props, context)

    const isMobile = Utils.isMobileOrTablet()

    this.state = {
      ready: false,
      error: false,
      hashid: this.props.params.hashid,
      gallery: false,
      customer: false,
      data: null,
      galleryMode: isMobile ? 'mobile-gallery' : '',
      isMobile
    }

    this.onContextMenu = this.onContextMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener('contextmenu', this.onContextMenu)
    this.getData()
  }

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.onContextMenu)
  }

  onContextMenu(e) {
    if (this.state && this.state.gallery && this.state.gallery.single_download) {
      return
    }
    e.preventDefault()
  }

  // eslint-disable-next-line max-statements
  async getData() {
    const [subdomain] = window.location.hostname.split('.')
    const { hashid } = this.props.params

    // console.log({ subdomain, hashid })

    const data = await API.getGallery(hashid)

    if (process.env.NODE_ENV !== 'development' && data.subdomain !== subdomain && data.url) {
      window.location = data.url
      return
    }

    if (data && data.error && data.error.message === 'Failed to fetch') {
      if (this.connRetries <= 3) {
        this.setState({ ready: true, error: 'ConnectionFailedError' })
        setTimeout(this.getData.bind(this), 6e3)
        this.connRetries++
        return
      }
      this.setState({ ready: true, error: 'ConnectionFailedError2' })
      return
    }

    if (!data || !data.customer || !data.hash || data.error) {
      this.setState({ ready: true, error: 'InvalidDataError' })
      return
    }

    const state = {
      ready: true,
      error: false,
      hashid,
      gallery: {
        title: data.title,
        allow_share: data.allow_share,
        single_download: data.single_download,
        download_all: data.download_all,
        cover_key: data.cover_key,
        url: data.url
      },
      customer: data.customer,
      data
    }

    this.setState(state)
    this.addManifest(state)

    this.isMobile = Utils.isMobileOrTablet()
    this.isPWA = this.isMobile && window.matchMedia('(display-mode: standalone)').matches

    if (this.isMobile && !this.isPWA) {
      InstallApp.open({ data })
    }

    this.updateAccessLog(state)
  }

  addManifest(state) {
    let el = document.getElementById('app-manifest')
    if (!el) {
      el = document.createElement('link')
      el.id = 'app-manifest'
      el.rel = 'manifest'
      document.head.appendChild(el)
    }

    const icons = []
    if (state.gallery.cover_key) {
      const coverPath = state.gallery.cover_key.split('customer/')[1]
      icons.push({
        src: `https://img.picsize.com.br/thumb/256/256/${coverPath}`,
        sizes: '256x256',
        type: 'image/png'
      })
    }

    const manifestObj = {
      name: state.gallery.title,
      short_name: state.gallery.title,
      display: 'standalone',
      description: `${state.gallery.title} por ${state.customer.displayName}.`,
      background_color: '#f2f2f2',
      theme_color: '#f8f8f8',
      icons
    }
    const blob = new Blob([JSON.stringify(manifestObj)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    el.setAttribute('href', url)
  }

  updateAccessLog(state) {
    const hash = state.hashid
    if (!hash) {
      return
    }
    const key = this.isPWA ? `gal-pwa-${hash}-last_access` : `gal-${hash}-last_access`
    let last_access
    try {
      last_access = JSON.parse(localStorage.getItem(key))
    } catch (ex) {
      last_access = null
    }
    if (last_access && Date.now() - last_access < 144e5) {
      return
    }
    localStorage.setItem(key, Date.now())
    // eslint-disable-next-line no-console
    API.pushAccessLog({ hash, service: 'gallery', isPWA: this.isPWA }, true).catch(e => console.warn(e))
  }

  get errorMessage() {
    switch (this.state.error) {
      case 'InvalidDataError':
        return 'Galeria não encontrada.'
      case 'ConnectionFailedError':
        return (
          <span>
            <i className="fa fa-spinner fa-pulse" /> Ops! Você pode ter ficado sem internet ou os nossos servidores
            estão sendo atualizados, aguarde um instante enquanto tentamos resolver.
          </span>
        )
      case 'ConnectionFailedError2':
        return 'Poxa, não conseguimos estabelecer uma conexão com os servidores, verifique a sua conexão de internet ou tente novamente mais tarde.'
      default:
        return 'Algo deu errado.'
    }
  }

  render() {
    if (!this.state.ready) {
      return (
        <div className={`sf-gallery-page loading ${this.state.galleryMode}`}>
          <Loading />
        </div>
      )
    }

    if (this.state.error) {
      return <div className={`sf-gallery-page loading ${this.state.galleryMode}`}>{this.renderErrorMessage()}</div>
    }

    return (
      <GalleryContext.Provider value={this.state}>
        <div className={`sf-gallery-page ${this.state.galleryMode}`}>
          <div id="gallery-page-outlet" className="sf-gallery-page-outlet">
            {this.state.data && <GalleryPicturesPage {...this.props} />}
          </div>
        </div>
      </GalleryContext.Provider>
    )
  }

  renderErrorMessage() {
    return (
      <section className="sel sel--loading">
        <div className="error-message">{this.errorMessage}</div>
      </section>
    )
  }
}

GalleryPage.propTypes = {
  params: PropTypes.any
}
