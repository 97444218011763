import React, { Component } from 'react'

import GalleryContext from '../GalleryContext'
import FullDialogs from './FullDialogs'

import './About.scss'

export default {
    open(props = {}, isMobile) {
        return FullDialogs.open(
            <GalleryAboutPage {...props} />,
            { className: `sf-gallery-about-dialog${isMobile ? ' mobile-gallery' : ''}`, method: 'portal' }
        )
    }
}

class GalleryAboutPage extends Component {
    static contextType = GalleryContext

    constructor(props, context) {
        super(props, context)


        if (!context || !context.customer) {
            this.state = { customer: false }
            return
        }

        this.state = {
            customer: context.customer,
            displayName: context.customer.displayName,
            displayEmail: context.customer.displayEmail,
            telephone: context.customer.telephone,
            bio: context.customer.bio,
            medias: this.parseMedias(context.customer.socialMedias)
        }
    }

    parseMedias(medias) {
        const customer = this.context.customer
        const arr = [
            {
                icon: 'fa fa-envelope-o',
                text: customer.displayEmail,
                href: `mailto:${customer.displayEmail}`,
                target: '_top'
            }
        ]
        if (customer.telephone) {
            arr.push({
                icon: 'fa fa-phone',
                text: customer.telephone,
                href: `tel:${customer.telephone}`,
                target: '_top'
            })
        }
        if (!medias) {
            return arr
        }
        const icons = {
            site: 'fa fa-globe',
            facebook: 'fa fa-facebook',
            instagram: 'fa fa-instagram',
            twitter: 'fa fa-twitter'
        }

        for (const k in medias) if (medias[k]) {
            arr.push({ type: k, icon: icons[k], ...this.parseHref(k, medias) })
        }
        return arr
    }

    parseHref(k, medias) {
        const prefixes = {
            site: '',
            facebook: 'https://www.facebook.com/',
            instagram: 'https://www.instagram.com/',
            twitter: 'https://twitter.com/'
        }
        const pattern = {
            facebook: 'facebook.com/',
            instagram: 'instagram.com/',
            twitter: 'twitter.com/',
        }
        const v = medias[k]
        if (k === 'facebook') {
            if (v.indexOf('facebook.com/') !== -1) {
                return { text: v.split('facebook.com/').pop(), href: v }
            }
            else {
                return { text: v, href: `${prefixes[k]}${v}` }
            }
        }
        if (pattern[k] && v.indexOf(pattern[k]) !== -1) {
            return { text: v.split(pattern[k]).pop(), href: v }
        }
        return { text: v, href: `${prefixes[k]}${v}` }
    }

    render() {
        if (!this.state.customer) {
            return null
        }
        return (
            <div className='sf-gallery-about'>

                <div className='about-card'>
                    <div className='card-title'>{this.state.displayName}</div>
                    <p className='card-text'>
                        {this.state.bio}
                    </p>
                    <ul className='card-list'>
                        {this.state.medias.map((i, id) => this.renderListItem(i, id, i.target))}
                    </ul>
                </div>

            </div>
        )
    }

    renderListItem({ icon, text, href }, id, target = 'blank') {
        return (
            <li className='list-item' key={`sf-about-${id}`}>
                <a target={target} href={href}>
                    <i className={icon}></i>
                    <span>{text}</span>
                </a>
            </li>
        )
    }

}
