/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

import './style.scss'

class GalleryItem extends Component {
  constructor(state, context) {
    super(state, context)
    // this.setState({src: this.props.picture.mini_thumb})
    this.imgRef = React.createRef()
  }

  shouldComponentUpdate({ width, height }) {
    if (this.props.width !== width || this.props.height !== height) {
      return true
    }
    return this.imgRef.current.attributes['data-is-visible'].value === '1'
  }

  componentDidMount() {
    this.props.observer.observe(this.imgRef.current)
  }

  componentWillUnmount() {
    this.props.observer.unobserve(this.imgRef.current)
  }

  render() {
    const { isSelected, isCommented, picture } = this.props
    const baseClassName = `gal__gallery__item ${picture.orientation}`
    let className = baseClassName
    if (isCommented) {
      className += ` ${className}--commented`
    } else if (isSelected) {
      className += ` ${className}--selected`
    }
    const style = {
      // left: this.props.x,
      // top: this.props.y,
      width: this.props.width,
      height: this.props.height,
      maxWidth: this.props.maxWidth,
      minWidth: this.props.minWidth
    }
    // console.log(style)
    return (
      <div className={className} id={this.props.id} style={style}>
        <picture onClick={this.props.openPicturePreview}>
          <img
            data-thumb-src={this.props.picture.thumb}
            data-mini-src={this.props.picture.mini_thumb}
            data-is-visible={0}
            // width={this.props.width}
            // height={this.props.height}
            ref={this.imgRef}
          />
        </picture>
        {this.props.allowCopy && (
          <Tooltip
            placement="bottomLeft"
            title={`${picture.downloading ? 'Preparando' : 'Faça'} download do foto`}>
            <button
              className={`download-btn ${picture.downloading ? 'loading' : ''} ${
                picture.suggested ? 'push-bottom' : ''
                }`}
              onClick={() => this.props.download(picture.id)}>
              <i className={`fa ${picture.downloading ? 'fa-spinner fa-pulse' : 'fa-download'}`} />
            </button>
          </Tooltip>
        )}
        {this.props.allowShare && (
          <Tooltip placement="bottomLeft" title="Compartilhar foto">
            <button
              className={`share-btn${picture.sharing ? ' active' : ''}${
                this.props.allowCopy ? '' : ' push-right'
                }`}
              onClick={e => this.props.share(e.currentTarget, picture.id)}>
              <i className={`fa fa-${picture.sharing ? 'close' : 'share'}`} />
            </button>
          </Tooltip>
        )}
      </div>
    )
  }
}

GalleryItem.propTypes = {
  id: PropTypes.any,
  picture: PropTypes.any,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  observer: PropTypes.any,
  download: PropTypes.func,
  share: PropTypes.func,
  openPicturePreview: PropTypes.func,
  allowCopy: PropTypes.bool,
  allowShare: PropTypes.bool,
  isSelected: PropTypes.bool,
  isCommented: PropTypes.bool
}

export default GalleryItem
