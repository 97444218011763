// eslint-disable-next-line max-statements
export function calc(imgs, aW) {
  const desiredHeight = 350
  aW -= 8

  let row = []
  let use = 0
  let id = 0, y = 0
  for (const i of imgs) {
    i.width = (i.originalWidth * desiredHeight) / i.originalHeight
    i.height = desiredHeight

    row[id] = i
    use += i.width

    if (use >= aW) {
      let exceded = aW - use
      let x
      for (x = y; x < row.length; x++) {
        let percent = row[x].width / use
        let decrease = exceded * percent
        let width = (row[x].width + decrease) - 8
        let height = (row[x].height * width) / row[x].width
        row[x].width = width
        row[x].height = height
      }
      y = x
      use = 0
    }

    id++
  }

}
