import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import swal from 'sweetalert'
import {
  Input,
  Button,
  Form,
  Alert,
  Divider,
  Icon,
  Row,
  Col
} from 'antd'

import API from '~/modules/API'

import './style.scss'

class LoginModal extends React.Component {
  static propTypes = {
    isGenericLink: PropTypes.bool,
    client: PropTypes.any,
    hash: PropTypes.any,
    isModal: PropTypes.any
  }

  constructor (props, context) {
    super (props, context)
    this.state = {
      isSubmitingLogin: false,
      isSubmitingRecover: false,
      loginEmail: '',
      loginPassword: '',
      loginValidation: false,

      isSubmitingSignup: false,
      signupName: '',
      signupTel: '',
      signupEmail: '',
      signupPassword: '',
      signupValidation: false,

      isGenericLink: props.isGenericLink
    }

    if (props.client) {
      this.state.loginEmail = props.client.email
    }

    this.onChange = this.onChange.bind(this)
    this.setActionValidation = this.setActionValidation.bind(this)
  }

  componentDidMount () {
    if (this.props.isModal) {
      const {pathname} = window.location

      if (!pathname.includes('login')) {
        window.appHistory.push(`${pathname}/login`)
      }

      window.appHistory.listen(this.onRouteChange)
    }
  }

  onRouteChange () {
    const {pathname} = window.location
    if (pathname && !pathname.includes('login')) {
      swal.close()
    }
  }

  maskPhone (v) {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 11) {
        // 12+ digitos. Formata como +55 (31) 9999-9999
        r = r.replace(/^(\d{2})(\d\d)(\d{5})(\d{4}).*/,"+$1 ($2) $3-$4");
    }
    else if (r.length > 5) {
        // 6...11 digitos. Formata como (31) 9999-9999
        r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/,"($1) $2-$3");
    }
    else if (r.length > 2) {
        // 3...5 digitos. Formata como (31) 9999
        r = r.replace(/^(\d\d)(\d{0,5})/,"($1) $2");
    }
    else {
        // 0...2 digitos. Apenas adiciona o primeiro parêntese
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

  onChange (value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }

    key === "signupTel" ? value = this.maskPhone(value) : null

    const newState = {[key]: value}

    this.setState(newState)
  }

  async submitLogin (event) {
    event.preventDefault()
    this.setState({isSubmitingLogin: true})

    const result = await API.postLogin(this.props.hash, {
      email: this.state.loginEmail,
      password: this.state.loginPassword
    })

    if (!result.error) {
      window.location = `/s/${result.hash}`
      return
    }

    if (result.error === 'INVALID_EMAIL') {
      return this.setActionValidation('login', {
        email: {
          validateStatus: 'error',
          help: 'Email inválido!'
        }
      })
    }

    if (result.error === 'INVALID_PASSWORD') {
      return this.setActionValidation('login', {
        password: {
          validateStatus: 'error',
          help: 'Senha incorreta!'
        }
      })
    }
  }

  async submitSignup (event) {
    event.preventDefault()
    this.setState({isSubmitingSignup: true})

    const result = await API.postSignup(this.props.hash, {
      name: this.state.signupName,
      tel: this.state.signupTel,
      email: this.state.signupEmail,
      password: this.state.signupPassword,
      url_origin: "true",
    })

    if (!result.error) {
      window.location = `/s/${result.newHash}`
      return
    }

    if (result.error === 'INVALID_EMAIL') {
      return this.setActionValidation('signup', {
        email: {
          validateStatus: 'error', help: 'Email inválido!'}
      })
    }

    if (result.error === 'CLIENT_EXISTS') {
      return this.setActionValidation('signup', {
        alert: {
          message: 'Você já possui o cadastro no sistema! Utilize a função "Esqueci a senha" caso não lembre.',
          type: 'warning',
          showIcon: true,
        },
        email: {
          validateStatus: 'error',
          help: 'Email já cadastrado!'
        }
      })
    }

    if (result.error === 'INVALID_PASSWORD') {
      return this.setActionValidation('signup', {
        password: {validateStatus: 'error', help: 'Senha muito curta! A senha deve ter ao menos 4 caracteres!'},
      })
    }

    if (result.error === 'INVALID_NAME') {
      return this.setActionValidation('signup', {
        name: {validateStatus: 'error', help: 'É necessário informar o seu nome!'},
      })
    }

    if (result.error === 'EXCEPTION') {
      return this.setActionValidation('signup', {
        alert: {
          message: 'Não foi possível cadastrar!',
          type: 'error',
          showIcon: true
        }
      })
    }
  }

  async recoverPassword (event) {
    event.preventDefault()

    if (!this.state.loginEmail && !this.state.loginEmail.length) {
      return this.setActionValidation('login', {
        email: {
          validateStatus: 'error',
          help: 'Informe o email cadastrado para recuperar a senha.'
        }
      })
    }


    this.setState({isSubmitingRecover: true})

    const result = await API.postRecover(this.props.hash, {
      email: this.state.loginEmail
    })

    if (!result.error) {
      return this.setActionValidation('login', {
        alert: {
          message: 'Um link de recuperação de senha foi enviado ao seu email!',
          type: 'success',
          showIcon: true
        },
        email: {
          validateStatus: 'success'
        }
      })
    }

    if (result.error === 'INVALID_EMAIL') {
      return this.setActionValidation('login', {
        email: {
          validateStatus: 'error',
          help: 'Email não cadastrado'
        }
      })
    }

    if (result.error === 'EXCEPTION') {
      return this.setActionValidation('login', {
        alert: {
          message: 'Não foi possível recuperar a senha!',
          type: 'error',
          showIcon: true
        }
      })
    }
  }

  // section = 'login' or 'signup'
  setActionValidation (section, validationData) {
    this.setState({
      loginValidation: false,
      signupValidation: false,
      isSubmitingRecover: false,
      isSubmitingLogin: false,
      isSubmitingSignup: false,
      [`${section}Validation`]: {...validationData}
    })
  }

  render () {
    return (
      <Row style={{height: '100%'}}>
        <Col md={{span: this.props.isGenericLink ? 11 : 24}} sm={{span: 24}}>
          <Divider orientation="left">Entrar</Divider>
          <LoginForm
            values={this.state}
            onChange={this.onChange}
            onSubmit={this.submitLogin.bind(this)}
            recoverPassword={this.recoverPassword.bind(this)}
          />
        </Col>

        {this.props.isGenericLink && (
          <Col md={{span: 11, offset: 2}} sm={{span: 24, offset: 0}}  className="signup-col">
            <Divider orientation="left">Cadastrar</Divider>
            <SignupForm
              values={this.state}
              onChange={this.onChange}
              onSubmit={this.submitSignup.bind(this)}
            />
          </Col>
        )}
      </Row>
    )
  }
}

function LoginForm ({values, onChange, onSubmit, recoverPassword}) {
  const loginValidation = !!values.loginValidation && values.loginValidation
  return (
    <Form layout="vertical" onSubmit={onSubmit}>
      {!!loginValidation.alert && (<Alert {...loginValidation.alert} />)}
      <Form.Item label="E-mail" key="loginEmail" {...(loginValidation && loginValidation.email)}>
        <Input
          name="loginEmail"
          value={values.loginEmail}
          onChange={onChange}
          disabled={!values.isGenericLink}
          prefix={(
            <Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />
          )}
        />
      </Form.Item>
      <Form.Item label="Senha" key="loginPassword" {...(loginValidation && loginValidation.password)}>
        <Input
          name="loginPassword"
          value={values.loginPassword}
          onChange={onChange}
          type="password"
          disabled={values.isSubmitingRecover}
          prefix={(
            <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />
          )}
        />
      </Form.Item>
      <Form.Item key="buttons">
        <Button type="dashed" style={{float: 'left'}} onClick={recoverPassword} loading={values.isSubmitingRecover}>
          Esqueci a senha
        </Button>
        <Button type="primary" htmlType="submit" style={{float: 'right'}} loading={values.isSubmitingLogin} disabled={values.isSubmitingRecover}>
          Entrar
        </Button>
      </Form.Item>
    </Form>
  )
}

LoginForm.propTypes = {
  values: PropTypes.any,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  recoverPassword: PropTypes.func
}

function SignupForm ({values, onChange, onSubmit}) {
  const signupValidation = !!values.signupValidation && values.signupValidation

  return (
    <Form layout="vertical" onSubmit={onSubmit}>
      {!!signupValidation.alert && (<Alert {...signupValidation.alert} />)}
      <Form.Item label="Nome" key="signupName" {...((signupValidation && signupValidation.name) || {})}>
        <Input
          name="signupName"
          value={values.signupName}
          onChange={onChange}
          prefix={(
            <Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />
          )}
        />
      </Form.Item>
      <Form.Item label="Whatsapp/Telefone" key="signupTel" {...((signupValidation && signupValidation.tel) || {})}>
        <Input
          name="signupTel"
          value={values.signupTel}
          onChange={onChange}
          prefix={(
            <Icon type="phone" style={{color: 'rgba(0,0,0,.25)'}} />
          )}
        />
      </Form.Item>
      <Form.Item label="E-mail" key="signupEmail" {...((signupValidation && signupValidation.email) || {})}>
        <Input
          name="signupEmail"
          value={values.signupEmail}
          onChange={onChange}
          prefix={(
            <Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />
          )}
        />
      </Form.Item>
      <Form.Item label="Senha" key="signupPassword" {...((signupValidation && signupValidation.password) || {})}>
        <Input.Password
          name="signupPassword"
          value={values.signupPassword}
          onChange={onChange}
          type="password"
          prefix={(
            <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />
          )}
        />
      </Form.Item>
      <Button htmlType="submit" loading={values.isSubmitingSignup}>
        Cadastrar
      </Button>
    </Form>
  )
}

SignupForm.propTypes = {
  values: PropTypes.any,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

LoginModal.show = async (hash, isGenericLink, client) => {
  let content = document.createElement('div')
  ReactDOM.render([
    <a
      key="modal-close"
      className="login-modal__close"
      onClick={() => swal.close()}
    >
      <Icon type="close-circle" />
    </a>,
    <LoginModal
      hash={hash}
      isGenericLink={isGenericLink}
      client={client}
      isModal
      key="login-modal"
    />
  ], content)

  return await swal({
    className: 'login-modal',
    content,
    dangerMode: false,
    title: false,
    icon: false,
    buttons: false
  }).then(() => {
    const {pathname} = window.location
    if (pathname && pathname.includes('login')) {
      window.history.back()
    }
  })
}

export default LoginModal
